import clsx from "clsx";
import { useEffect, useRef, useState } from "react";
import { CSSTransition, SwitchTransition } from "react-transition-group";

import cooking from "~/assets/img/home-cooking.png";
import learning from "~/assets/img/home-learning.png";
import recipes from "~/assets/img/home-recipes.png";
import shopping from "~/assets/img/home-shopping.png";

const Features = () => {
  const data = [
    {
      title: "recipes",
      text: (
        <span>
          Recipes made <span className="text-gold-main">for you</span>
        </span>
      ),
      subtext: "Never too easy, never too hard, always delicious.",
      img: recipes,
    },
    {
      title: "shopping",
      text: (
        <span>
          Shopping made <span className="text-gold-main">stress free</span>
        </span>
      ),
      subtext: "A smart grocery list, for breezy trips to the store.",
      img: shopping,
    },
    {
      title: "learning",
      text: (
        <span>
          Learning made <span className="text-gold-main">easy</span>
        </span>
      ),
      subtext: "We’ll teach you the hows and whys of cooking.",
      img: learning,
    },
    {
      title: "cooking",
      text: (
        <span>
          Cooking made <span className="text-gold-main">fun</span>
        </span>
      ),
      subtext: "Answers to your questions always one click away.",
      img: cooking,
    },
  ];
  const [highlight, setHighlight] = useState(data[0]);

  const headingRef = useRef(null);
  const textRef = useRef(null);
  const imgRef = useRef(null);

  const canvasRef = useRef<any>(null);
  const [minHeight, setMinHeight] = useState(0);

  useEffect(() => {
    const { height } = canvasRef?.current?.getBoundingClientRect();
    setMinHeight(height);
  }, [highlight]);

  return (
    <div
      className={clsx(["container mx-auto overflow-hidden py-6", "xl:py-28"])}
    >
      {/* Preload all the images */}
      {data.map((item) => (
        <img
          src={item.img}
          alt={item.title}
          className="hidden"
          key={item.title}
        />
      ))}
      <SwitchTransition>
        <CSSTransition
          key={highlight.title}
          nodeRef={headingRef}
          timeout={300}
          classNames="home-heading"
        >
          <h3
            className={clsx([
              "mx-auto pb-1 text-center font-logo text-3xl text-gray-main transition-opacity",
              "sm:pb-8",
              "md:pb-4 md:text-5xl",
              "xl:pb-8 xl:text-7xl",
            ])}
            ref={headingRef}
          >
            {highlight.text}
          </h3>
        </CSSTransition>
      </SwitchTransition>
      <div>
        <div className={clsx([])}>
          <div className="sm:min-w-[600px]">
            <div
              className={clsx([
                "mx-auto grid max-w-[1100px] translate-y-1/2 grid-cols-4 justify-items-center",
              ])}
            >
              {data.map((item, index) => (
                <button
                  key={item.title}
                  onClick={() => setHighlight(item)}
                  className={clsx([
                    "text-md bg-gold-main mx-1 rounded-md px-3 py-1 font-apercu text-white transition-colors",
                    "sm:mx-2 sm:px-6 sm:py-2 sm:font-bold sm:text-lg",
                    "md:px-12",
                    "xl:mx-10 xl:px-12 xl:py-3 xl:text-xl",
                  ])}
                >
                  {item.title}
                </button>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div
        className={clsx([
          "width-full border-gold-main bg-gold-lightest mx-4 overflow-hidden rounded-[10px] border-4 px-4 pt-10",
          "md:rounded-[20px] md:pt-16",
          "xl:rounded-[60px] xl:pt-28",
        ])}
        ref={canvasRef}
        style={{
          minHeight: `${minHeight}px`,
        }}
      >
        <SwitchTransition>
          <CSSTransition
            key={highlight.title}
            nodeRef={textRef}
            timeout={400}
            classNames="home-text"
          >
            <h4
              className={clsx([
                "mx-auto text-center font-bold text-xl text-text-black",
                "xl:max-w-[600px] xl:text-4xl",
                "xl:max-w-[1000px] xl:text-6xl",
              ])}
              ref={textRef}
            >
              {highlight.subtext}
            </h4>
          </CSSTransition>
        </SwitchTransition>
        <div
          className={clsx([
            "h-[320px] pt-10",
            "xl:min-h-[400px] xl:pt-20",
            "xl:min-h-[600px]",
          ])}
        >
          <SwitchTransition>
            <CSSTransition
              key={highlight.title}
              nodeRef={imgRef}
              timeout={500}
              classNames="home-image"
            >
              <img
                src={highlight.img}
                alt={highlight.title}
                className="mx-auto h-full max-h-[650px]"
                ref={imgRef}
              />
            </CSSTransition>
          </SwitchTransition>
        </div>
      </div>
    </div>
  );
};

export default Features;
